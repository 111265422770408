<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'ManagementUserProfilesManage',

  components: {
    Modal: () => import('@/components/general/Modal'),
    Radio: () => import('@/components/general/Radio'),
    Action: () => import('@/components/general/Action'),
    InputField: () => import('@/components/general/InputField'),
    FormSection: () => import('@/components/general/FormSection'),
    AppMultiselect: () => import('@/components/general/AppMultiselect')
  },

  data () {
    return {
      groupList: [],
      formData: {
        id: null,
        name: null,
        solutionGroups: null,
        restrictedAccess: false,
        permissions: {
          solution: 'add.edit',
          tracks: 'add.edit',
          // programs: null,
          // themes: null,
          sessions: null,
          // session_attendance: null,
          users: 'add.edit',
          enrollment: 'add.edit',
          // entrepreneur_profiles: null,
          target_audience: null,
          profiles: 'add.edit',
          solution_themes: 'add.edit',
          surveys: 'add.edit',
          certificates: 'add.edit',
          banners: null,
          // gamification: null,
          reports: 'add.edit',
          reports_students: 'add.edit',
          reports_enrollments: 'add.edit',
          reports_enrollments_learning_path: null,
          reports_enrollments_program: null,
          reports_surveys_answers: 'add.edit',
          // reports_canvas_discussion: null,
          reports_learning_path_resume: 'add.edit',
          reports_solutions_resume: 'add.edit',
          reports_programs_resume: null,
          // reports_rae_services: null,
          reports_solution_alerts: 'add.edit',
          // reports_sas_events: null,
          // reports_financial: null,
          reports_comments: 'add.edit',
          reports_events: 'add.edit',
          configurations: 'add.edit',
          events: 'add.edit',
          // dashboard: null,
          impersonate: 'view',
          search_engine: 'add.edit',
          notification: 'add.edit',
          thematic_pages: 'add.edit',
          // TODO: descomentar quando modularizar mediation_plans e filters
          // filters: 'add.edit',
          // mediation_plans: 'add.edit',
          comments: 'add.edit',
          groups: 'add.edit',
          webhooks: 'add.edit'
        }
      },

      basicOptions: [
        {
          label: this.$t('management.user.profiles:modal.form.permissions.add.edit'),
          value: 'add.edit'
        },
        {
          label: this.$t('management.user.profiles:modal.form.permissions.disable'),
          value: 'disable'
        }
      ],

      dashboardOptions: [
        {
          label: this.$t('management.user.profiles:modal.form.permissions.add.edit'),
          value: 'view'
        },
        {
          label: this.$t('management.user.profiles:modal.form.permissions.disable'),
          value: 'disable'
        }
      ],

      searchEngineOptions: [
        {
          label: this.$t('management.user.profiles:modal.form.permissions.add.edit'),
          value: 'add.edit'
        },
        {
          label: this.$t('management.user.profiles:modal.form.permissions.disable'),
          value: 'disable'
        }
      ],

      impersonateOptions: [
        {
          label: this.$t('management.user.profiles:modal.form.permissions.add.edit'),
          value: 'view'
        },
        {
          label: this.$t('management.user.profiles:modal.form.permissions.disable'),
          value: 'disable'
        }
      ],

      restrictedAccessOptions: [
        {
          label: this.$t('management.user.profiles:modal.form.option.restricted.access.without_restrict'),
          value: false
        },
        {
          label: this.$t('management.user.profiles:modal.form.option.restricted.access.with_restrict'),
          value: true
        }
      ],

      sectionList: []
    }
  },

  validations: {
    formData: {
      name: {
        required
      },

      restrictedAccess: { required },

      solutionGroups: {
        required: requiredIf(function () {
          return this.formData.restrictedAccess
        }),

        minGroupsLength: (value, formData) => {
          return formData.restrictedAccess ? value ? value.length > 0 : true : true
        }
      },

      permissions: {
        solution: {
          required
        },

        tracks: {
          required
        },

        // programs: {
        //   required
        // },
        // themes: {
        //   required
        // },
        // sessions: {
        //   required
        // },

        // session_attendance: {
        //   required
        // },
        users: {
          required
        },

        enrollment: {
          required
        },

        // target_audience: {
        //   required
        // },

        profiles: {
          required
        },

        solution_themes: {
          required
        },

        surveys: {
          required
        },

        certificates: {
          required
        },

        // banners: {
        //   required
        // },

        // gamification: {
        //   required
        // },
        reports: {
          required
        },

        reports_students: {
          required
        },

        reports_enrollments: {
          required
        },

        reports_enrollments_learning_path: {
          required
        },

        // reports_enrollments_program: {
        //   required
        // },

        reports_surveys_answers: {
          required
        },

        // reports_canvas_discussion: {
        //   required
        // },
        reports_learning_path_resume: {
          required
        },

        reports_solutions_resume: {
          required
        },

        // reports_rae_services: {
        //   required
        // },

        reports_solution_alerts: {
          required
        },

        // events: {
        //   required
        // },

        // reports_sas_events: {
        //   required
        // },
        // reports_financial: {
        //   required
        // },

        // reports_comments: {
        //   required
        // },

        configurations: {
          required
        },

        // dashboard: {
        //   required
        // },
        search_engine: {
          required
        },

        impersonate: {
          required
        },

        notification: {
          required
        },

        // thematic_pages: {
        //   required
        // },
        // TODO: descomentar quando modularizar mediation_plans filters
        // filters: {
        //   required
        // },

        // mediation_plans: {
        //   required
        // },

        // comments: {
        //   required
        // },

        groups: {
          required
        }

        // webhooks: {
        //   required
        // }
      }
    }
  },

  computed: {
    ...mapState([ 'fetching' ]),
    ...mapGetters([ 'activeModules' ]),

    isEditing () {
      return !!this.$route.params.id
    },

    title () {
      if (this.isEditing) {
        return this.$t('management.user.profiles:modal.title.editing')
      }

      return this.$t('management.user.profiles:modal.title.add')
    },

    clientAlias () {
      if (!process.env.VUE_APP_CLIENT_ALIAS) {
        return 'lite'
      }

      return process.env.VUE_APP_CLIENT_ALIAS
    },

    sections () {
      return [
        {
          tag: 'solution'
        },
        {
          tag: 'tracks'
        },
        {
          tag: 'programs',
          module: 'program'
        },
        // {
        //   tag: 'themes'
        // },
        {
          tag: 'sessions',
          module: 'closed-session'
        },
        {
          tag: 'finances',
          module: 'ecommerce'
        },
        // {
        //   tag: 'session_attendance'
        // },
        {
          tag: 'users'
        },
        {
          tag: 'enrollment'
        },
        {
          tag: 'target_audience',
          module: 'target-audience'
        },
        {
          tag: 'profiles'
        },
        {
          tag: 'solution_themes'
        },
        {
          tag: 'surveys'
        },
        {
          tag: 'certificates'
        },
        {
          tag: 'banners'
        },
        {
          tag: 'gamification',
          module: 'gamification'
        },
        // {
        //   tag: 'reports'
        // },
        {
          tag: 'reports_students'
        },
        {
          tag: 'reports_enrollments'
        },
        {
          tag: 'reports_enrollments_learning_path'
        },
        {
          tag: 'reports_enrollments_program',
          module: 'program'
        },
        {
          tag: 'reports_surveys_answers'
        },
        // {
        //   tag: 'reports_canvas_discussion'
        // },
        {
          tag: 'reports_learning_path_resume'
        },
        {
          tag: 'reports_solutions_resume'
        },
        {
          tag: 'reports_programs_resume',
          module: 'program'
        },
        {
          tag: 'reports_rae_services',
          module: 'rae'
        },
        {
          tag: 'reports_solution_alerts'
        },
        {
          tag: 'reports_events',
          module: 'event'
        },
        {
          tag: 'events',
          module: 'event'
        },
        {
          tag: 'reports_sas_events',
          module: 'sas'
        },
        {
          tag: 'reports_financial',
          module: 'ecommerce'
        },
        {
          tag: 'reports_comments',
          module: 'assessment'
        },

        {
          tag: 'configurations'
        },
        // {
        //   tag: 'dashboard',
        //   items: this.dashboardOptions
        // },
        {
          tag: 'search_engine',
          items: this.searchEngineOptions
        },
        {
          tag: 'impersonate',
          items: this.impersonateOptions
        },
        {
          tag: 'notification'
        },
        {
          tag: 'thematic_pages',
          module: 'thematic-page'
        },
        // TODO: descomentar quando modularizar mediation_plans filters
        // {
        //   tag: 'filters'
        // },
        // {
        //   tag: 'mediation_plans'
        // },

        {
          tag: 'comments',
          module: 'assessment'
        },

        {
          tag: 'groups'
        },
        {
          tag: 'webhooks'
        }
      ]
    }
  },

  created () {
    this.sections
      .sort((a, b) => this.$t('management.user.profiles:modal.form.' + a.tag).localeCompare(this.$t('management.user.profiles:modal.form.' + b.tag)))
      .forEach((section) => {
        if (section.module === undefined || this.activeModules.includes(section.module)) {
          // TODO: Remover esse trecho de código apos a implementação de modularizar os banners
          if (section.tag === 'banners' && this.clientAlias === 'sescoop_pr') {
            return
          }

          this.sectionList.push(section)
          this.formData.permissions[section.tag] = section.tag === 'impersonate' ? 'view' : 'add.edit'
        }
      })

    if (this.isEditing) {
      this.setup()
    } else {
      this.getGroupsList()
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetProfile',
      'attemptCreateProfile',
      'attemptUpdateProfile',
      'attemptGetLoggedUser',
      'attemptGetGroupsList'
    ]),

    formatFormData () {
      const formData = {
        id: this.formData.id,
        name: this.formData.name,
        groups: this.formData.restrictedAccess ? this.formData.solutionGroups.filter(item => item.value !== 'all').map(item => item.value) : [],
        restricted_access: this.formData.restrictedAccess,
        permissions: {}
      }

      for (const i in this.formData.permissions) {
        if (this.formData.permissions[i] === 'add.edit') {
          formData.permissions[i] = 'write'
        }

        if (this.formData.permissions[i] === 'view') {
          formData.permissions[i] = 'read'
        }
      }

      if (this.checkPermissionReport(formData.permissions)) {
        formData.permissions.reports = 'write'
      } else {
        // eslint-disable-next-line no-unused-vars
        const { reports, ...permissions } = formData.permissions

        formData.permissions = permissions
      }

      return formData
    },

    checkPermissionReport (permissions) {
      return Object.keys(permissions).some(permission => permission.includes('reports_'))
    },

    // leave () {
    //   this.modalConfirm = false

    //   this.$nextTick(() => {
    //     this.$router.push(this.backUrl)
    //   })
    // },

    submitCreation () {
      this.setFetching(true)

      this.attemptCreateProfile(this.formatFormData()).then(() => {
        this.setFeedback({ message: this.$t('management.user.profile:feedback.created.success') })
        this.$router.push({ name: 'management.user.profiles' })
      }).catch(() => {
        this.setFeedback({ message: this.$t('management.user.profile:feedback.created.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    submitUpdate () {
      this.setFetching(true)

      this.attemptUpdateProfile(this.formatFormData()).then(() => {
        this.setFeedback({ message: this.$t('management.user.profile:feedback.update.success') })

        this.attemptGetLoggedUser().then(() => {
          this.$router.push({ name: 'management.user.profiles' })
        })
      }).catch(() => {
        this.setFeedback({ message: this.$t('management.user.profile:feedback.update.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    submit (next) {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.isEditing ? this.submitUpdate(next) : this.submitCreation(next)
      } else {
        this.setFeedback({ message: this.$t('management.user.profile:feedback.validation.error') })
      }
    },

    closeModal () {
      this.$router.push({ name: 'management.user.profiles' })
    },

    setup () {
      this.setFetching(true)

      Promise.all([
        this.attemptGetProfile(this.$route.params.id)
          .then(data => {
            this.formData = data
            this.formData.restrictedAccess = data.restrictedAccess

            this.formData.solutionGroups = data.groupProfile.map(item => {
              return {
                text: item.group.name,
                value: item.group.id
              }
            })
          }),
        this.getGroupsList()
      ])
        .finally(() => {
          this.setFetching(false)
        })
    },

    getGroupsList () {
      return this.attemptGetGroupsList({
        filter: {
          active: true
        },
        limit: 9999
      })
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.groupList.push({
                text: element.name,
                value: element.id
              })
            })

            return true
          }
        })
    },

    addSolutionGroup (item) {
      if (!this.formData.solutionGroups) {
        this.formData.solutionGroups = []
      }

      if (item) {
        if (item.value === 'all') {
          this.formData.solutionGroups = this.groupList
        } else {
          this.formData.solutionGroups.push(item)
        }
      }
    },

    removeSolutionGroup (item) {
      if (item) {
        if (item.value === 'all') {
          this.formData.solutionGroups = []
        } else {
          this.formData.solutionGroups = this.formData.solutionGroups.filter(solutionGroup => solutionGroup.value !== item.value)
        }
      }
    }
  }
}
</script>

<template>
  <Modal
    :active="!fetching"
    @close="closeModal"
  >
    <div class="modal-form modal-add-user">
      <span class="modal-subtitle">{{ $t('management:modal.subtitle') }}</span>

      <h2 class="modal-title">
        {{ title }}
      </h2>

      <span class="modal-form-description">
        <strong>{{ $t('management.user.profiles:modal.description_bold') }}</strong>
      </span>

      <form @submit.prevent="submit()">
        <InputField
          v-model="formData.name"
          dark
          :label="$t('management.user.profiles:modal.form.name')"
          :validation="$v.formData.name"
          :counter="30"
        />

        <p class="form-group-title">
          {{ $t('management.user.profiles:modal.form.modality') }}
        </p>

        <FormSection>
          <Radio
            v-model="formData.restrictedAccess"
            :under-description="$t('management.user.profiles:modal.form.under.description.restricted.access')"
            :items="restrictedAccessOptions"
            horizontal
            dark
            :disabled="isEditing"
            :validation="$v.formData.restrictedAccess"
          />

          <AppMultiselect
            v-if="formData.restrictedAccess"
            v-model="formData.solutionGroups"
            :options="groupList"
            dark
            :placeholder="$t('global:form.groups.select')"
            :validation="$v.formData.solutionGroups"
            @onSelect="addSolutionGroup"
            @onRemove="removeSolutionGroup"
          />
        </FormSection>

        <p class="form-group-title">
          {{ $t('management.user.profiles:modal.form.permissions') }}
        </p>

        <FormSection
          v-for="section in sectionList"
          :key="section.title"
          uppercase
          class="form-section-permissions"
          :title="$t('management.user.profiles:modal.form.' + section.tag)"
        >
          <Radio
            v-model="formData.permissions[section.tag]"
            dark
            horizontal
            :items="section.items ? section.items : basicOptions"
            :validation="$v.formData.permissions[section.tag]"
          />
        </FormSection>

        <div class="form-submit text-center">
          <Action
            type="button"
            secondary
            large
            submit
            fixed-width
            :text="$t('global:form.save')"
          />
        </div>
      </form>
    </div>
  </Modal>
</template>

<style lang="scss">
.form-group-title {
  font-family: 'Lato';
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  margin-top: 80px;
  margin-bottom: 50px;

  &:first-child {
    margin-bottom: 0;
  }
}

.form-section-permissions {
  margin: 48px 0;

  .form-item-horizontal .form-radio-item {
    margin-right: 48px;
  }
}
</style>
