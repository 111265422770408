<script>
import { mapActions } from 'vuex'

export default {
  name: 'UsersList',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionSubmenu: () => import('@/components/general/ActionSubmenu'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datatable: () => import('@/components/general/Datatable'),
    Dropdown: () => import('@/components/general/Dropdown'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    FilterList: () => import('@/components/general/FilterList'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm'),
    Pagination: () => import('@/components/general/Pagination')
  },

  data () {
    return {
      pagination: {
        query: {
          name: null,
          username: null,
          email: null,
          cellphone: null
        },

        page: 1,
        lastPage: null
      },

      modalRemoveOpen: false,
      showSubmenu: false,
      showSubmenuBatch: false,
      removeUserId: null,
      removeIndex: null,
      searchUserBind: null
    }
  },

  computed: {
    usersList () {
      return this.$store.getters.usersList
    },

    hasPermissionEnrollment () {
      return !!this.getContextPermission('enrollment')
    },

    canSeeImpersonate () {
      return !!this.getContextPermission('impersonate')
    }
  },

  watch: {
    'pagination.page' () {
      this.getUserList()
    }
  },

  created () {
    if (this.$route.name === 'users.index') {
      if (this.$route.params) {
        this.searchUserBind = this.$route.params.searchUserBind
        this.searchUser(this.searchUserBind)
      } else {
        this.getUserList()
      }
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetUsersList',
      'attemptManageUser',
      'attemptRemoveUser',
      'attemptImpersonateUser',
      'attemptSendValidationEmail'
    ]),

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    getStatusTag (item) {
      if (!item) return
      const statusTag = {
        text: '',
        class: ''
      }

      if (item && item.completedRegistration) {
        statusTag.text = 'Completo'

        if (item.validatedEmail) {
          statusTag.text += ' e Validado'
          statusTag.class = 'active-ribbon'
        } else {
          statusTag.text += ' e Não Validado'
          statusTag.class = 'warning-ribbon'
        }
      } else {
        statusTag.text = 'Incompleto'
        statusTag.class = 'inactive-ribbon'
      }

      return statusTag
    },

    getUserList () {
      this.setFetching(true)

      this.attemptGetUsersList(this.pagination)
        .then(pagination => {
          this.pagination.lastPage = pagination.lastPage
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    openEnrollment (user) {
      this.$router.push({
        name: 'users.enrollment',
        params: {
          id: user.id,
          person: user
        }
      })
    },

    impersonate (userId) {
      this.setFetching(true)

      this.attemptImpersonateUser(userId)
        .then(response => {
          window.open(
            `${process.env.VUE_APP_PORTAL_URL}autologin/${response.data.token}/${response.data.refresh_token}`,
            '_blank'
          )
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    createNewAdmin () {
      this.attemptManageUser(null)
      this.$router.push({ name: 'users.admin.manage' })
    },

    createNewUser () {
      this.attemptManageUser(null)
      this.$router.push({ name: 'users.manage' })
    },

    createNewApi () {
      this.attemptManageUser(null)
      this.$router.push({ name: 'users.api.manage' })
    },

    createNewUserBatch () {
      this.attemptManageUser(null)
      this.$router.push({ name: 'users.batch.manage' })
    },

    createNewEnrollmentBatch () {
      this.attemptManageUser(null)
      this.$router.push({ name: 'enrollment.batch.manage' })
    },

    batchList () {
      this.$router.push({ name: 'batch.list' })
    },

    searchUser (search) {
      this.searchUserBind = search
      this.pagination.query.name = search
      this.pagination.query.username = search
      this.pagination.query.email = search
      this.pagination.query.cellphone = search
      this.pagination.page = 1
      this.getUserList()
    },

    edit (userId) {
      this.attemptManageUser(userId)

      if ((this.$store.getters.getManagingUser.roles[0] === 'ROLE_ADMIN')) {
        this.$router.push({
          name: 'users.admin.manage',
          params: {
            id: userId,
            searchUserBind: this.searchUserBind
          }
        })
      } else if (this.$store.getters.getManagingUser.roles[0] === 'ROLE_EXTERNAL_API') {
        this.$router.push({
          name: 'users.api.manage',
          params: {
            id: userId,
            searchUserBind: this.searchUserBind
          }
        })
      } else {
        this.$router.push({
          name: 'users.manage',
          params: {
            id: userId,
            searchUserBind: this.searchUserBind
          }
        })
      }
    },

    sendValidationEmail (userId) {
      this.setFetching(true)

      this.attemptSendValidationEmail(userId)
        .then(() => {
          this.setFeedback({ message: this.$t('users:feedback.send.email.success') })
        })
        .catch(() => {
          this.setFeedback({ message: this.$t('users:feedback.send.email.error') })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    remove () {
      this.modalRemoveOpen = false
      this.setFetching(true)

      this.attemptRemoveUser(this.removeUserId)
        .then(() => {
          this.$store.getters.usersList.splice(this.removeIndex, 1)
          this.setFeedback({ message: this.$t('community.users:remove.success') })
        })
        .catch(() => {
          this.setFeedback({ message: this.$t('users:feedback.remove.error') })
        })
        .finally(() => {
          this.setFetching(false)
          this.removeUserId = null
          this.removeIndex = null
        })
    },

    confirmRemove (userId, index) {
      this.removeUserId = userId
      this.removeIndex = index
      this.modalRemoveOpen = true
    },

    cancelRemove () {
      this.modalRemoveOpen = false
    },

    openSubmenu () {
      this.showSubmenu = !this.showSubmenu
    },

    openSubmenuBatch () {
      this.showSubmenuBatch = !this.showSubmenuBatch
    },

    userType (props) {
      return this.isAdmin(props)
        ? this.$t('users.list:datatable.admin')
        : this.isExternalApi(props)
          ? this.$t('users.list:datatable.external_api')
          : this.$t('users.list:datatable.student')
    },

    isAdmin (props) {
      if (!props.item.roles) return

      return props.item.roles.includes('ROLE_ADMIN')
    },

    isExternalApi (props) {
      if (!props.item.roles) return

      return props.item.roles.includes('ROLE_EXTERNAL_API')
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('users:header.title')"
      :description="$t('users:header.description')"
      background="/assets/images/themes/default/png/users_banner.png"
    />
    <FilterList>
      <ActionSubmenu
        slot="button"
        :show.sync="showSubmenu"
        position="bottom"
      >
        <Action
          slot="button"
          primary
          large
          fixed-width
          type="button"
          :text="$t('users.list:btn.add')"
          @click="openSubmenu"
        />
        <template slot="actions">
          <Action
            type="button"
            :text="$t('users.management:add.new.admin')"
            secondary
            small
            dark
            @click="createNewAdmin()"
          />
          <Action
            type="button"
            :text="$t('users.management:add.new.student')"
            secondary
            small
            dark
            @click="createNewUser()"
          />
          <Action
            type="button"
            :text="$t('users.management:add.new.api')"
            secondary
            small
            dark
            @click="createNewApi()"
          />
        </template>
      </ActionSubmenu>
      <ActionSubmenu
        slot="button"
        :show.sync="showSubmenuBatch"
        position="bottom"
      >
        <Action
          slot="button"
          class="showSubmenuBatch"
          primary
          large
          fixed-width
          type="button"
          :text="$t('users.list:btn.batch')"
          @click="openSubmenuBatch"
        />
        <template slot="actions">
          <Action
            type="button"
            :text="$t('users.management:add.new.student.batch')"
            secondary
            small
            dark
            @click="createNewUserBatch()"
          />
          <Action
            v-if="hasPermissionEnrollment"
            type="button"
            :text="$t('users.management:add.new.enrollment.batch')"
            secondary
            small
            dark
            @click="createNewEnrollmentBatch()"
          />
        </template>
      </ActionSubmenu>
      <FilterListSearch
        slot="search"
        :hide-error-details="true"
        :do-search="searchUserBind"
        @search="searchUser"
      />
      <Action
        slot="button"
        class="showSubmenuBatch"
        type="button"
        :text="$t('users.management:batch.list')"
        borderless
        large
        @click="batchList()"
      />
    </FilterList>
    <div
      v-if="usersList.length > 0"
      class="center users-table"
    >
      <Datatable :items="usersList">
        <template slot="thead">
          <tr>
            <!--
 <th class="th">{{ $t('users.list:datatable.header.1') }}</th>
-->
            <th class="th">
              {{ $t('users.list:datatable.header.6') }}
            </th>
            <!--
 <th class="th" width="200">{{ $t('users.list:datatable.header.2') }}</th>
-->
            <th class="th">
              {{ $t('users.list:datatable.header.3') }}
            </th>
            <th class="th">
              {{ $t('users.list:datatable.header.4') }}
            </th>
            <th
              class="th text-center"
              width="200"
            >
              {{ $t('users.list:datatable.header.5') }}
            </th>
            <!--
 <th class="th" width="40"></th>
-->
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder td-block">{{ props.item.name }}</span>
              <span
                class="td-text"
                :class="isAdmin(props) ? 'has-admin' : ''"
              >{{ userType(props) }}</span>
            </td>
            <td class="td">
              <span class="td-text">{{ props.item.email || '-' }}</span>
            </td>
            <td
              class="td"
              width="250"
            >
              <span class="td-text">{{ props.item.username }}</span>
            </td>
            <td class="td text-center">
              <span
                class="td-tag"
                :class="getStatusTag(props.item).class"
              >{{ getStatusTag(props.item).text }}</span>
            </td>
            <td
              class="td td-actions"
              width="40"
            >
              <Dropdown
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  v-if="!isAdmin(props) && !isExternalApi(props) && canSeeImpersonate"
                  :text="$t('global:impersonate')"
                  @click="impersonate(props.item.id)"
                />
                <DropdownLink
                  v-if="!isAdmin(props) && !isExternalApi(props) && hasPermissionEnrollment"
                  :text="$t('global:management')"
                  @click="openEnrollment(props.item)"
                />
                <DropdownLink
                  v-if="!props.item.validatedEmail && !isAdmin(props) && !isExternalApi(props)"
                  :text="$t('global:send.validation.email')"
                  @click="sendValidationEmail(props.item.id)"
                />
                <DropdownLink
                  :text="$t('global:edit')"
                  @click="edit(props.item.id)"
                />
                <DropdownLink
                  :text="$t('global:remove')"
                  @click="confirmRemove(props.item.id, props.index)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>
      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
    </div>
    <RouterView />
    <ModalConfirm
      :active="modalRemoveOpen"
      :title="$t('users.manage:remove.title')"
      :description="$t('users.manage:remove.description')"
      @confirmAction="remove"
      @cancelAction="cancelRemove"
    />
  </div>
</template>

<style>
.showSubmenuBatch {
  margin-left: 10px;
}

.datatable .td-block {
  margin-bottom: 4px;
}

.has-admin {
  color: rgba(0,0,0,0.4);
}
</style>
